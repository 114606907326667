$(function () {
  var siteheadHeight;
  var menublockHeight;
  siteheadHeight = $(".site-head").height();
  menublockHeight = $(".menu-block").height();
  $(window).scroll(function () {
    if ($(window).scrollTop() > siteheadHeight) {
      $(".menu-block-home").addClass("stick"); // $(".menu-block-inner").addClass("stick");
    } else {
      $(".menu-block-home").removeClass("stick"); // $(".menu-block-inner").removeClass("stick");
    }
  });
  $('.search-block-header-button').click(function () {
    $('.search-block-header').toggleClass('search-block-header-open');
    $('.menu-header').toggleClass('menu-header-close');
  });
  $('.search-block-header-button').click(function () {
    $('.site-head-search-form-input').focus();
  }); //   var urlWithoutQS = window.location.pathname
  //   console.log(urlWithoutQS);
  //if(urlWithoutQS == '/'){
  //  $('.site-head').addClass("")    
  //}else{
  //  $('.site-head').addClass("site-head-custom")    
  //}
  //   if (urlWithoutQS == '/') {
  //       $('.site-body-inner').addClass("")
  //   } else {
  //       $('.site-body-inner').addClass("container container-fluid site-body-margin")
  //   }
  // $(".scroll-to-block-trigger a").click(function () {
  //     elementClick = $(this).attr("href");
  //     destination = $(elementClick).offset().top - 75;
  //     $("html:not(:animated),body:not(:animated)").animate({
  //         scrollTop: destination
  //     }, 800);
  //     return false;
  // });
}); // $(document).ready(function () {
//     var openSocials = function () {
//         var btn = $("#product-social-open"),
//             socialBtns = $('.product-social-btns .social-btns');
//         btn.on("click", function () {
//             socialBtns.slideToggle(200);
//         });
//     }
//     openSocials();
// })