import '../styles/general.scss';
import '../styles/partials/menu-header.scss';
import '../styles/partials/toolbar-top.scss';
import '../styles/partials/header.scss';
import '../styles/partials/price.scss';
import '../styles/views/product.scss';
import '../scripts/_common/carousel/carousel.module.js';
import '../styles/partials/footer-menu.scss';
import '../styles/common/buttons.scss';
import '../styles/partials/products-specials.scss';
import '../scripts/_partials/product-view/productView.module.js';
import '../scripts/_partials/cart/styles/cart.scss';
import '../scripts/_partials/colors-viewer/colorsViewer.module.js';
import '../scripts/_partials/sizes-viewer/sizesViewer.module.js';
import '../scripts/_partials/catalog-filter/catalogFilter.module.js';
import '../vendors/new.js';